
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { mdiDownload, mdiEarth } from '@mdi/js'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { resolveCountry } from '@/plugins/i18n'

import { useNotify } from '@/store'

import { translationsApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired } from '@/utils/validation'

export default defineComponent({
  name: 'AdminImportExportTranslations',
  components: {
    CommonFlagIcon,
    CommonAutocomplete,
  },
  props: {
    allLocales: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup: (props, { emit, root }) => {
    const { addNotification } = useNotify()
    const localeFileUploadForm = ref<ValidationForm | null>(null)

    const fileLocale = ref('')
    const fileInput = ref<File | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const { uploadTranslationsFile } = translationsApi.useUploadTranslationsFile()

    const uploadTranslationFile = () => {
      if (localeFileUploadForm.value?.validate()) {
        if (fileInput.value) {
          uploadTranslationsFile(fileInput.value, fileLocale.value)
            .then(() => {
              addNotification({
                type: 'success',
                text: root.$t('translations.upload.success') as string,
              })

              // fileModalActive.value = false

              emit('file-uploaded')
              location.reload()
            })
            .catch((error) => {
              error.userMessage = root.$t('translations.upload.error')
              throw error
            })
        }
      }
    }

    return {
      icons: { mdiDownload, mdiEarth },
      localeFileUploadForm,
      fileInput,
      fileLocale,
      uploadTranslationFile,
      implementedLocales,
      isRequired,
      resolveCountry,
    }
  },
})
