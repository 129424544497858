var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('translations.importExport.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', [_c('v-col', [_c('h5', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.$t('translations.download.title')))])])], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "href": "/v1/translations/file?type=resource-bundle",
      "target": "_self",
      "block": ""
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDownload) + " ")]), _vm._v(" " + _vm._s(_vm.$t('form.action.download')) + " ")], 1)], 1)], 1), _c('v-divider'), _c('v-form', {
    ref: "localeFileUploadForm",
    attrs: {
      "id": "locale-file-upload-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.uploadTranslationFile.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', [_c('h5', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.$t('translations.upload.title')) + " ")])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('translations.upload.prompt')) + " ")])], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "label": _vm.$tc('form.field.locale', 1),
      "items": _vm.implementedLocales,
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          staticClass: "mr-2",
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', {
          staticClass: "mr-2"
        }, [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiEarth) + " ")]) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.fileLocale,
      callback: function callback($$v) {
        _vm.fileLocale = $$v;
      },
      expression: "fileLocale"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-file-input', {
    attrs: {
      "rules": [_vm.isRequired(_vm.fileInput, _vm.$t('form.field.translationsFile'))],
      "label": _vm.$t('form.field.translationsFile'),
      "accept": ".xlf,.xliff,.properties",
      "outlined": ""
    },
    model: {
      value: _vm.fileInput,
      callback: function callback($$v) {
        _vm.fileInput = $$v;
      },
      expression: "fileInput"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "locale-file-upload-form"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.upload')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }